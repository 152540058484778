
.checkout-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: minmax(min-content, calc(50% + 72px)) 1fr;

        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    > .left-container {
        width 100%
        max-width: 570px;
        background-color: #fff
        border-right: none;
        margin: 0 auto;
        min-height: 100dvh;

        @media screen and (min-width: 1024px) {
            max-width: 100%;
            border-right: 1px solid #dedede;
            margin: 0;
        }

        > .section-information {
            width 100%
            max-width: 100dvw
            padding: 0 21px 0;
            display: flex;
            flex-direction: column;
            gap: 26px;

            @media screen and (min-width: 1024px)  {
                padding: 0 22px 0;
                max-width: 660px;
                margin-left: auto;
            }
        }
    }

    >.right-container {
        width 100%
        background-color: #fff
        position: relative;


        @media screen and (min-width: 1024px) {
            width 100%
            background-color: $color-grey-light-80;
            min-height: 100dvh;
        }

        >.section-summary {
            width 100%
            max-width: 100dvw
            padding: 21px;

            display flex;
            flex-direction: column;
            gap: 32px;

            @media screen and (min-width: 1024px) {
                position: sticky;
                top: 72px;

                padding: 38px;
                width 100%
                max-width: 520px;
                margin-right: auto;

                display flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}

.section-summary-sticky {
    background-color: $color-grey-light-80;

    grid-column: span 2;
}

.section-summary-sticky-accordion {
    width 100%
    max-width: 100dvw
    padding: 21px 21px 0;

    display flex;
    flex-direction: column;
    gap: 21px;
}

.full-page
    overflow: visible !important
